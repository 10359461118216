/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react'

import { Button } from 'reactstrap'

type Props = {
	bgColor: string
	handleBgClick: (color: string) => void
	activeColor: string
	handleActiveClick: (color: string) => void
}
type State = {
	classes: string
}

class FixedPlugin extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			classes: 'dropdown',
		}
		this.handleClick = this.handleClick.bind(this)
	}
	handleClick() {
		if (this.state.classes === 'dropdown') {
			this.setState({ classes: 'dropdown show' })
		} else {
			this.setState({ classes: 'dropdown' })
		}
	}
	render() {
		return (
			<div className='fixed-plugin'>
				<div className={this.state.classes}>
					<div onClick={this.handleClick}>
						<i className='fa fa-cog fa-2x' />
					</div>
					<ul className='dropdown-menu show'>
						<li className='header-title'>SIDEBAR BACKGROUND</li>
						<li className='adjustments-line'>
							<div className='badge-colors text-center'>
								<span
									className={
										this.props.bgColor === 'black'
											? 'badge filter badge-dark active'
											: 'badge filter badge-dark'
									}
									data-color='black'
									onClick={() => {
										this.props.handleBgClick('black')
									}}
								/>
								<span
									className={
										this.props.bgColor === 'white'
											? 'badge filter badge-light active'
											: 'badge filter badge-light'
									}
									data-color='white'
									onClick={() => {
										this.props.handleBgClick('white')
									}}
								/>
							</div>
						</li>
						<li className='header-title'>SIDEBAR ACTIVE COLOR</li>
						<li className='adjustments-line'>
							<div className='badge-colors text-center'>
								<span
									className={
										this.props.activeColor === 'primary'
											? 'badge filter badge-primary active'
											: 'badge filter badge-primary'
									}
									data-color='primary'
									onClick={() => {
										this.props.handleActiveClick('primary')
									}}
								/>
								<span
									className={
										this.props.activeColor === 'info'
											? 'badge filter badge-info active'
											: 'badge filter badge-info'
									}
									data-color='info'
									onClick={() => {
										this.props.handleActiveClick('info')
									}}
								/>
								<span
									className={
										this.props.activeColor === 'success'
											? 'badge filter badge-success active'
											: 'badge filter badge-success'
									}
									data-color='success'
									onClick={() => {
										this.props.handleActiveClick('success')
									}}
								/>
								<span
									className={
										this.props.activeColor === 'warning'
											? 'badge filter badge-warning active'
											: 'badge filter badge-warning'
									}
									data-color='warning'
									onClick={() => {
										this.props.handleActiveClick('warning')
									}}
								/>
								<span
									className={
										this.props.activeColor === 'danger'
											? 'badge filter badge-danger active'
											: 'badge filter badge-danger'
									}
									data-color='danger'
									onClick={() => {
										this.props.handleActiveClick('danger')
									}}
								/>
							</div>
						</li>
						<li className='button-container'>
							<Button
								href='https://www.creative-tim.com/product/paper-dashboard-react?ref=pdr-fixed-plugin'
								color='primary'
								block
								className='btn-round'
							>
								Download now
							</Button>
						</li>
						<li className='button-container'>
							<Button
								href='https://www.creative-tim.com/product/paper-dashboard-react/#/documentation/tutorial?ref=pdr-fixed-plugin'
								color='default'
								block
								className='btn-round'
								outline
							>
								<i className='nc-icon nc-paper' /> Documentation
							</Button>
						</li>
						<li className='header-title'>Want more components?</li>
						<li className='button-container'>
							<Button
								href='https://www.creative-tim.com/product/paper-dashboard-pro-react?ref=pdr-fixed-plugin'
								color='danger'
								block
								className='btn-round'
								target='_blank'
							>
								Get pro version
							</Button>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default FixedPlugin
